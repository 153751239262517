import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import IconArrowLeft from 'src/assets/icon/IconArrowLeft';
import Loading from 'src/components/common/loading';
import 'src/components/features/superAdmin/detailContent.modules.scss';
import { Button, message, Form, Input, Spin, Select } from 'antd';
import IconTrash from 'src/assets/icon/IconTrash';
import ModalConfirm from 'src/components/common/modal/modalConfirm';
import { useMutation } from 'react-query';
import IconEdit from 'src/assets/icon/IconEdit';
import { useDeleteAcrylicPlate } from 'src/api/useDeleteAcrylicPlate';
import { useDetailAcrylicPlate } from 'src/api/useDetailAcrylicPlate';
import NotFoundPage from 'src/pages/notFoundPage';
import { useCheckAcrylic } from 'src/api/useCheckAcrylic';
import { STATUS_CODE_NOT_PERMISS_DELETE_ACRYLIC } from 'src/assets/constants';

const AcrylicPlatesDetailComponent = () => {
  const [antForm] = Form.useForm();
  const history = useNavigate();
  const { id } = useParams<{ id: string }>();

  const { data: detailData, isLoading: isLoadingDetail, refetch, isError } = useDetailAcrylicPlate(Number(id));
  const { isLoading: isLoadingDelete, mutate: onDelete } = useMutation(
    (id: number | string) => useDeleteAcrylicPlate(id),
    {
      onSuccess: async () => {
        refetch();
        message.success('香水ラベル削除は成功しました');
        setIsOpenModalDelete(false);
        history('/product/acrylic-plates');
      },
      onError: () => {
        message.error('システムにエラーが発生しました。後でもう一度お試しください');
      },
    }
  );

  const { isLoading: isCheckAcrylicToDelete, mutate: onCheckAcrylicToDelete } = useMutation(() => useCheckAcrylic(id), {
    onSuccess: () => {
      setIsOpenModalDelete(true);
    },
    onError: (e: any) => {
      if (e?.response?.status === STATUS_CODE_NOT_PERMISS_DELETE_ACRYLIC) {
        setIsOpenModalAlert(true);
      }
    },
  });

  const [isOpenModalDelete, setIsOpenModalDelete] = useState<boolean>(false);
  const [isOpenModalAlert, setIsOpenModalAlert] = useState<boolean>(false);

  const handleGoBack = () => {
    history('/product/acrylic-plates');
  };

  const handleConfirmDelete = () => {
    if (id) {
      onDelete(id);
    }
  };

  const onNavigateListTemplate = () => {
    history('/product/templates');
  };

  const handleCloseModalDelete = () => {
    setIsOpenModalDelete(false);
  };

  const handleCloseModalAlert = () => {
    setIsOpenModalAlert(false);
  };

  const handleOpenModalDelete = () => {
    setIsOpenModalDelete(true);
  };

  const handleGoToEditNotify = () => {
    history(`/product/acrylic-plates/edit/${id}`);
  };

  if ((!detailData || Object.keys(detailData).length === 0 || isError) && !isLoadingDetail) {
    return <NotFoundPage />;
  }

  return (
    <>
      {isLoadingDetail ? (
        <Loading />
      ) : (
        <div className="page-content company-page">
          <div className="page-content-title border-bottom-title mb-[30px] flex items-center justify-between">
            <div className="inline-flex items-center cursor-pointer" onClick={handleGoBack}>
              <div className="mr-[17px]">
                <IconArrowLeft />
              </div>
              <span className="line-1">香水ラベルの詳細</span>
            </div>

            <div className="flex items-center justify-end px-[12px] py-[8px]">
              <div className="flex gap-[20px] ml-auto">
                <Button className="button-add h-[48px] w-[115px]" onClick={handleGoToEditNotify}>
                  <IconEdit className="mr-[10px]" />
                  編集
                </Button>
                <Button className="button-remove w-[115px] h-[48px]" onClick={() => onCheckAcrylicToDelete()}>
                  <IconTrash className="mr-[10px]" /> 削除
                </Button>
              </div>
            </div>
          </div>
          <Form form={antForm} className="form-create w-[900px] mx-auto" layout="vertical" autoComplete="off">
            <div className="pb-[12px]">
              <div className="mb-[16px]">
                <Form.Item required label="香水ラベル名" name="name" initialValue={detailData?.title}>
                  <Input disabled className="input-form" />
                </Form.Item>
              </div>
              <div className="mb-[30px] mt-[30px] bg-blue p-[20px]">
                  <Form.Item required label="Image Preview" name="image_url">
                    <div className="w-[200px] h-[300px] mt-2">
                      <img
                      src={detailData?.image_url}
                      alt="thumbnail"
                      className="thumbnail-upload !rounded-none"
                      />
                  </div>
                </Form.Item>
              </div>

              <Form.List name="acrylic_plate_sizes" initialValue={detailData?.acrylic_plate_sizes}>
                {(acrylicPlateList, { add, remove }) => (
                  <>
                    {acrylicPlateList?.map((item: any, index: any) => {
                      return (
                        <div className="mt-[30px] bg-blue p-[20px]" key={item?.key}>
                          <div className="mb-[30px]">
                            <Form.Item required label="画像のサムネイル" name={[item?.name, 'image_url']}>
                              <div className="w-[100px] h-[125px]">
                                <img
                                  src={detailData?.acrylic_plate_sizes?.[index]?.image_url}
                                  alt="thumbnail"
                                  className="thumbnail-upload "
                                />
                              </div>
                            </Form.Item>
                          </div>
                          <div className="mb-[30px] flex items-center gap-[30px]">
                            <Form.Item required label="サイズ" name={[item?.name, 'size_title']} className="w-[257px]">
                              <Input disabled className="input-form" value={item?.size_title} />
                            </Form.Item>
                            <Form.Item required label="単価（円）" name={[item?.name, 'price']} className="w-[257px]">
                              <Input disabled className="input-form" />
                            </Form.Item>
                            <Form.Item
                              required
                              label="配送料（円）"
                              name={[item?.name, 'shipping_fee']}
                              className="w-[257px]"
                            >
                              <Input disabled className="input-form" />
                            </Form.Item>
                          </div>
                          <div className="mb-[30px] flex items-center gap-[30px]">
                            <Form.Item required label="比率" name={[item?.name, 'aspect_ratio']} className="w-[257px]">
                              <Input disabled className="input-form" />
                            </Form.Item>
                            <Form.Item required label="高さ（mm）" name={[item?.name, 'height']} className="w-[257px]">
                              <Input disabled className="input-form" />
                            </Form.Item>
                            <Form.Item required label="幅（mm）" name={[item?.name, 'width']} className="w-[257px]">
                              <Input disabled className="input-form" />
                            </Form.Item>
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </Form.List>
            </div>
          </Form>
        </div>
      )}
      <ModalConfirm
        isShowModal={isOpenModalDelete}
        onCancel={handleCloseModalDelete}
        onOk={handleConfirmDelete}
        content="削除確認"
        subContentPrefix="選択した「"
        subContent={detailData?.title}
        subContentSuffix="」を削除してもよろしいですか？"
        loadingButtonOk={isLoadingDelete}
        isClose={false}
        isIcon={false}
        textOk="削除"
      />
      <ModalConfirm
        isShowModal={isOpenModalAlert}
        onCancel={handleCloseModalAlert}
        onOk={onNavigateListTemplate}
        content="削除できません"
        subContentPrefix="連携しているテンプレートがあります。削除する場合は該当のテンプレートを編集・削除にしてください。"
        loadingButtonOk={isCheckAcrylicToDelete}
        isClose={false}
        isIcon={false}
        textOk="テンプレート一覧へ"
      />
    </>
  );
};

export default AcrylicPlatesDetailComponent;
